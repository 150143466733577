.app{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.application-number{
    margin-bottom: 2rem;
}

.applications{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}


.info{
    font-size: 18px;
}

.btns-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
}
