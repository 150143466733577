.sidebar{
    width: 20%;
    border-right:1px solid #b7b7b7 ;
    display: flex;
    flex-direction: column;
}

.header{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    width: 100%;
    height: 6vh;
    border-bottom: 1px solid #b7b7b7;
}

@media (min-width: 920px) and (max-width: 1280px){
    .sidebar{
        min-width: 20vw;
    }
}

@media (min-width: 720px ) and (max-width: 920px){
    .sidebar{
        min-width: 25vw;
    }
}

@media (min-width: 520px) and (max-width: 720px){
    .sidebar{
        min-width: 30vw;
    }
}

@media (max-width: 520px){
    .sidebar{
        min-width: 42vw;
    }
}
