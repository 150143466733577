.lastpage {
  background-color: aliceblue;
  width: 85%;
  align-items: center;
  margin: 100px;
  padding: 35px;
  border: 1px solid #efedf6;
  border-radius: 10px;
}

.confirm {
  display: inline-flex;
  justify-content: left;

  margin: 0 5px;
  height: 40px;
}

.side-titles {
  float: center;
  padding: 30px 50px;
}

.block-type {
  background-color: white;
  height: auto;
  margin: auto;
  width: 70%;
  padding: 5px;
  color: rgb(183, 109, 11);
  border: 1px solid #efedf6;
  border-radius: 10px;
}

.midd {
  display: flex;
  height: 50px;
  align-items: center;
  padding: 50px;
}

.details-container {
  color: #777585;
  display: grid;
  grid-template-columns: auto auto auto auto;
  padding: 10px;
}

.media {
  color: #777585;
  display: grid;
  padding: 10px;
}
.multi-skills {
  width: 70%;
  /* float: right; */
  display: grid;
  grid-template-columns: auto auto auto auto auto;
}
.skill-set {
  width: 20%;
  float: left;
  margin: 10px;
}
.admin-input {
  color: #c49b9b;
  padding: 7px;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
}
.user-input {
  color: #020202;
  padding: 7px;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
}

.button_space {
  padding-top: 20px;
}
