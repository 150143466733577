

  .job-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 20px; 
    background-color: #f5f5f5;
    min-height: 100vh; 
    overflow-y: auto;
  }.job-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: auto;
    background-color: #f5f5f5;
    min-height: 100vh;
    padding: 20px;
    overflow-y: auto;

  }
  
  .create-job {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 100%;
    max-width: 600px;
  
    overflow: hidden; 
  }
  
  .form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: auto;
    max-height: calc(100vh - 40px);
    overflow-y: auto; 
    padding-right: 10px; 
  }
  
  .form::-webkit-scrollbar {
    width: 8px;
  }
  
  .form::-webkit-scrollbar-thumb {
    background: #007bff;
    border-radius: 4px;
  }
  
  .form::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  .input-text {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: space-between;
  }
  
  .form-row {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .tags {
    font-size: 0.9rem;
    font-weight: 500;
    margin-bottom: 5px;
    color: #555;
  }
  
  .form-control {
    padding: 10px;
    font-size: 0.95rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
    transition: border-color 0.3s ease;
  }
  
  .form-control:focus {
    border-color: #007bff;
    box-shadow: 0 0 3px rgba(0, 123, 255, 0.5);
  }
  
  
  .heading h2 {
    font-size: 1.8rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .input-text {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: space-between;
  }
  
  .form-row {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .tags {
    font-size: 0.9rem;
    font-weight: 500;
    margin-bottom: 5px;
    color: #555;
  }
  
  .form-control {
    padding: 10px;
    font-size: 0.95rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
    transition: border-color 0.3s ease;
    
  }
  
  .form-control:focus {
    border-color: #007bff;
    box-shadow: 0 0 3px rgba(0, 123, 255, 0.5);
  }
  
  textarea.form-control {
    resize: none;
  }
  
  select.form-control {
    appearance: none;
  }
  
  #updatejobbtns {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  button.created {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button.created:hover {
    background-color: #0056b3;
  }
  
  button.created:active {
    background-color: #003f8a;
  }
  
  #updatejobbtn {
    background-color: #28a745;
  }
  
  #updatejobbtn:hover {
    background-color: #218838;
  }
  
  #updatejobbtn:active {
    background-color: #1e7e34;
  }
  
  @media (max-width: 768px) {
    .input-text {
      flex-direction: column;
      gap: 10px;
    }
  
    .form-row {
      width: 100%;
    }
  
    #updatejobbtns {
      flex-direction: column;
      gap: 10px;
    }
  }

.job-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }

  .job-modal {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 100%;
    max-width: 600px;
    max-height: 90vh;
    overflow-y: auto;
    padding: 20px;
    animation: popup 0.3s ease-in-out;
    position: relative;
  }
  
  @keyframes popup {
    from {
      transform: scale(0.9);
      opacity: 0;
    }
    to {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  .job-modal .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    color: #333;
    cursor: pointer;
    transition: color 0.3s ease;
  }
  
  .job-modal .close-btn:hover {
    color: #007bff;
  }
  