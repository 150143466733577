.help-page {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.contactus-container {
  margin-top: 6rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.container2 h1 {
  font-weight: 500;
}

.contactus-container p {
  text-align: center;
  width: 70%;
  font-size: 20px;
}

.help-form {
  max-width: 600px;
  margin-bottom: 2rem;
  padding: 20px;
  background-color: rgb(3, 5, 59);
  box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
  border: none;
  border-radius: 15px;
  color: #fff;
  width: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.help-form h2 {
  color: white;
}

.form-group {
  margin-bottom: 10px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input[type="text"],
input[type="email"],
input[type="password"],
textarea {
    width: 100%;
    /* margin-bottom: 1rem; */
    padding-left: .6rem;
    border: 1px solid #ccc;
    margin-bottom: 1rem;
}

textarea {
  height: 140px;
}

.submit-btn {
  padding: 10px 20px;
  background-color: #0077b5;
  color: #fff;
  border: none;
  border-radius: 0.3rem;
  cursor: pointer;
  margin-left: 5rem;
}

button:hover {
  background-color: #0095f8;
  color: white;
}

button:active {
  transform: scale(0.98);
}

@media (max-width: 480px) {
  .contactus {
    margin-top: -2rem;
  }
  .contactus-container p {
    text-align: justify;
    width: 85%;
  }

  .help-form {
    width: 90%;
  }
}

@media (max-width: 840px) {
  .help-form {
    width: 90%;
  }
}
