.chat-info{
    width: 80%;
}
.display-name{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 6vh;
    font-weight: bold;
    font-size: 20px;
    color: black;
    border-bottom: 1px solid #b7b7b7;
}

@media (max-width: 520px){

    .chat-info{
        min-width: 80%;
    }

}

@media (min-width: 920px) and (max-width: 1280px){
    .chat-info{
        min-width: 45vw;
    }
}

@media (min-width: 720px ) and (max-width: 920px){
    .chat-info{
        min-width: 45vw;
    }
}

@media (min-width: 520px) and (max-width: 720px){
    .chat-info{
        min-width: 60vw;
    }
    .display-name{
        min-width: 60vw;
    }
}
