* {
  font-family: Poppins, sans-serif;
}

h3 {
	font-size: 1.4rem;
	font-weight: 400;
	color: #f123ff;
}

strong {
	color: #f123ff;
}

main.main_wrapper {
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  text-align: left;

  & > section,
  header {
    width: min(60rem, 90%);
    margin-inline: auto;
  }

  header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 4rem;
    position: relative;
    padding: 3rem 0;

    &::after {
      content: "";
      position: absolute;
      width: 200vw;
      height: 100%;
      left: -50%;
      background-color: #f1ece6;
      margin-top: 1rem;
      z-index: -1;

      @media (max-width: 768px) {
        width: 200vw;
      }
    }

    & > h1 {
      font-size: 3rem;
      font-weight: 600;
    }

    span {
      font-style: italic;
      font-weight: 300;
      &:first-of-type {
        font-size: 1.5rem;
        margin-top: 1rem;
        color: #f123ff;
      }
    }
  }

	section.table__of__content {
		margin-bottom: 2rem;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin-bottom: 2rem;

		& > h2 {
			font-size: 2rem;
			font-weight: 600;
			margin-bottom: 1rem;
		}

		ol {
			text-align: left;
			list-style: lower-latin;

			li {
				margin-bottom: 0.5rem;
				font-size: 1.2rem;

				a {
					font-weight: 300;
					color: #f123ff;
				}
			}
		}
	}
}
