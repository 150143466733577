/* .work-experience-form {
  margin-bottom: 20px;
} */

.experience-section {
  margin-bottom: 10px;
  /* border: 1px solid #ccc; */
  padding: 10px;
}

.experience-section.collapsed {
  display: none;
}

.experience-section input {
  margin-right: 10px;
}

.experience-section button {
  margin-left: 200px;
  border: none;
  background-color: white;
  width: 100%;
  height: 50px;
}

/* button {
  margin-left: 50px;
  border: none;
  background-color: white;
  width: auto;
  height: 50px;
} */

/* .work-experience-form button {
  margin-top: 10px;
} */

.text p {
  margin-top: 5px;
  font-size: 13px;
  font-weight: 700;
  color: #cbced4;
}

/* .input-text {
  margin: 22px 0;
  display: flex;
  gap: 20px;
  color: #000000;
  font-weight: bold;
} */
.input-text1{
  display: flex;
}

.input-div input:focus ~ span,
.input-div input:valid ~ span {
  top: -15px;
  left: 6px;
  font-size: 10px;
}

input[type="radio"] {
  border: 6px double black;
  padding: 12px;
  display: flex;
  flex-direction: row;
}
button {
  border: none;
  background-color: #fff;
}
.work-type {
  padding: 27px 30px;
}

.add {
  width: auto;
  height: 30px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  border: none;
}
.del,
i {
  width: auto;
  padding: 0px 10px;
}
.add-button,
.del-button {
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 2rem;
  padding: 0.8rem 1.2rem 0.8rem 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease-out;
  font-size: 0.7rem;
  box-shadow: 0 0.2rem 0.2rem rgba(0, 0, 0, 0.1),
    0 0.5rem 1rem rgba(0, 0, 0, 0.3);
}
.del-button {
  background-color: #ef5d40;
  color: white;
}
.add-button:hover,
.del-button:hover {
  background-color: #0ea5e9;
  transform: translateY(-0.1rem);
  box-shadow: 0 0.3rem 0.2rem rgba(0, 0, 0, 0.1),
    0 0.6rem 1rem rgba(0, 0, 0, 0.3);
}

.bu {
  padding-top: 10px;
}
.bu button {
  height: 40px;
  width: 140px;
  border: none;
  border-radius: 5px;
  background-color: #0ea5e9;
  font-size: 15px;
  color: #fff;
  cursor: pointer;
  font-weight: bold;
}

.button_space {
  display: inline-flex;
  gap: 50px;
}
.button_space button:nth-child(1) {
  background-color: #cbced4;
  color: #000;
  border: 1px solid#000;
}

.circCont:after {
  width: 24px;
  height: 4px;
  background-color: #0ea5e9;
  content: "";
  left: 50%;
  top: 50%;
  margin-left: -12px;
  margin-top: -2px;
  position: absolute;
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  /*@include transform-origin(100%,100%);*/
}
.circCont:before {
  left: 50%;
  top: 50%;
  margin-left: -12px;
  margin-top: -2px;
  width: 24px;
  height: 4px;
  background-color: #0ea5e9;
  content: "";
  position: absolute;
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  /*@include transform-origin(0%,0%);*/
}
