
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(3, 3, 61);
  padding: 1rem;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.navbar-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.navbar-left {
  display: flex;
  align-items: center;
}

.navbar-logo {
  font-size: 1.5rem;
  font-weight: bold;
  color: white; 
}

.text-blue-500 {
  color: #3b82f6;
}

.mobile-menu-icon {
  display: none;
}

.navbar-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-link {
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  color: white; 
  font-weight: 500;
}

.navbar-link:hover {
  background-color: #ddd;
  border-radius: 4px;
}

.logout {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.logout .fa-sign-out-alt {
  margin-right: 0.5rem;
}

@media (max-width: 768px) {
  .navbar-right {
    display: none;
    flex-direction: column;
    position: sticky;
    top: 60px;
    right: 0;
    margin-top: 40px;
    background-color: rgb(3, 3, 61);
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 80;
  }

  .navbar-right.open {
    display: flex;
  }

  .mobile-menu-icon {
    display: block;
    cursor: pointer;
    color: white;
  }

  .navbar-left {
    flex: 1;
  }
}
