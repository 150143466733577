/* 1st section */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.home {
  -webkit-font-smoothing: antialiased;
  height: 100%;
  /* display: flex; */
  /* align-items: center; */
  padding: 0;
  margin: 0;
  color: rgb(0, 0, 0);
  font-family: Lato, "Helvetica Neue", sans-serif;
}

/* html {
  box-sizing: border-box;
  line-height: 1.4;
} */

a {
  color: rgb(255, 255, 255);
  font-weight: 700;
  text-decoration: none;
  transition: 0.3s;
}

main a {
  box-shadow: inset 0 -2px white;
  color: #229fbf;
}
main a:hover {
  box-shadow: inset 0 -30em #f2b139;
  color: white;
}

h1 {
  text-align: center;
  font-size: 3.6em;
  line-height: 1.4;
}

h2 {
  font-family: "Roboto Slab", serif;
  font-size: 1.8em;
  line-height: 1.4;
  margin-top: 1em;
  position: relative;
  margin-bottom: 10px;
  text-rendering: optimizeLegibility;
  /* margin-bottom: 1em; */
}

h3 {
  font-weight: 400;
  /* font-size: 1.4em; */
  line-height: 1.4;
  margin-top: 1.3em;
  margin-bottom: 1.3em;
  color: #229fbf;
}

img {
  /* width: 100%; */
  height: auto;
}

p {
  margin: 1rem 0;
}

.topping {
  padding-top: 10px;
  padding-bottom: 20px;
  height: 700px;
  background-color: white;
  position: inherit;
  margin-top: 4.7em;
}
.content {
  font-size: 19px;
  /* width: 100%; */
}
.header-title {
  color: rgb(23, 23, 24);
  font-family: Lato, "Helvetica Neue", sans-serif;
  font-size: 2.8em;
  line-height: 1.4;
  margin-bottom: 1em;
  top: 20px;
  float: left;
  font-weight: bold;
  width: auto;
}

.header-description {
  color: rgb(0, 50, 81);
  font-weight: 700;

  font-size: 1.4em;
  line-height: 1.4;
  /* margin-top: 1em; */
  margin-bottom: 10em;
  width: 500px;
  z-index: 1;
  /* margin-left: 1em; */
}

.header-box {
  position: absolute;
  top: 60%;
  left: 0%;
  height: 100px;

  color: rgb(1, 1, 1);
  padding: 20px;
}
.cta {
  position: relative;
  top: 80%;
  left: -150px;
  padding: 11px 18px;
  transition: all 0.2s ease;
  border: none;
  background: none;

  float: left;
}

.cta:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  border-radius: 50px;
  background: rgb(3, 3, 61);
  width: 45px;
  height: 45px;
  transition: all 0.3s ease;
}

.cta span {
  position: relative;
  font-family: "Ubuntu", sans-serif;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.05em;
  color: #ff7200;
}

.cta svg {
  position: relative;
  top: 0;
  margin-left: 10px;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: rgb(3, 3, 61);
  stroke-width: 2;
  transform: translateX(-5px);
  transition: all 0.3s ease;
}

.cta:hover:before {
  width: 100%;
  background: #229fbf;
}

.cta:hover svg {
  transform: translateX(0);
}

.cta:active {
  transform: scale(0.95);
}

/* Styles for screens smaller than 768px (Mobile) */
@media (max-width: 767px) {
  .topping {
    height: auto;
    margin-top: 0;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .header-title {
    font-size: 1.8em;
    line-height: 1.2;
    width: 100%;
    padding-top: 20%;
    padding-right: 30px;
  }
  .header-title span {
    color: #ff7200;
    font-size: 35px;
    /* margin-left: 40%; */
  }
  .header-box {
    position: relative;
    height: 300px;
  }
  .header-description {
    font-size: 1.2em;
    margin-bottom: 3em;
    width: 100%;
    margin-left: 0;
  }

  .cta {
    left: 40px;
    margin-right: auto;
    float: none;
  }

  .background-img {
    height: 200px;
    width: 100%;
    margin-left: 0;
    padding: 20px;
  }
}

/* Styles for screens between 768px and 1023px (Tablet) */
@media (min-width: 768px) and (max-width: 1023px) {
  .header-title {
    font-size: 2.4em;
    line-height: 1.3;
    float: left;

    margin-bottom: 3em;
    margin-top: 60px;
  }
  .header-box {
    position: absolute;
    height: 200px;
    top: 50%;
  }
  .header-description {
    font-size: 1.2em;
    margin-bottom: 5em;
    width: 250px;
  }
  .header-title span {
    color: #ff7200;
    font-size: 45px;
    /* margin-left: 30%; */
  }
  .background-img {
    height: 600px;
    width: auto;
    /* margin-left: 40%; */
  }
}
@media screen and (min-width: 1024px) {
  .background-img {
    height: 600px;
    width: auto;
    margin-left: 40%;
  }
  .header-image {
    padding: 4.2rem;
  }
  .header-image .button {
    margin: 2.8rem 2.8rem 0;
  }

  .header-title {
    line-height: 1.4;
    margin-top: 1.5em;
    padding-left: 0.2em;

    font-family: "Times New Roman";
  }
  .header-title span {
    color: #ff7200;
    font-size: 65px;
    /* margin-left: 40%; */
  }
  .header-description {
    font-size: 1.4em;
    line-height: 1.4;
    margin-bottom: 1.3em;
    /* float: left; */
  }
}
/* .full-width {
  width: 100%;
} */
/* 
.skils {
  float: left;
} */

/* @-webkit-keyframes fadein {
  100% {
    opacity: 1;
  }
} */

/* @keyframes fadein {
  100% {
    opacity: 1;
  }
} */
/* .fixed-width {
  padding: 10px;
  text-align: center;
}
.fixed-width::after {
  clear: both;
  content: "";
  display: table;
}

.fixed-width h3 {
  white-space: nowrap;
} */

.col {
  padding: 1.4rem;
}

.small-box {
  border-radius: 3px;
  display: block;
  margin: 1.4rem 2.5%;
  padding-top: 0;
  padding-bottom: 1.4rem;
  transition: 0.3s;
}
.small-box:hover {
  transform: translateY(-1.4rem);
}

.site-title {
  display: none;
  font-size: 1.8rem;
  margin: 0;
}

.site-header {
  background: #ffffff;
  height: 650px;
}

.header-image .button {
  margin: 1rem;
}

.highlighted-bg {
  background: #ecf0f3;
  box-shadow: 0 4px 0 #b8d0dc;
}

.icon:before {
  padding-right: 0.7rem;
}

.icon-large {
  color: #6da768;
  line-height: 0;
  font-size: 8.4em;
  margin-top: -1.3rem;
}
.icon-large:before {
  padding: 0;
}

@-webkit-keyframes slidedown {
  100% {
    opacity: 1;
    transform: translate3d(-50%, -50%, 0);
  }
}

@keyframes slidedown {
  100% {
    opacity: 1;
    transform: translate3d(-50%, -50%, 0);
  }
}

@import url("https://fonts.googleapis.com/css?family=Exo+2:700");
* {
  margin: 0 auto;
  padding: 0;
}

.about {
  /* box-shadow: 5px 5px 15px 3px lightblue;; */
  overflow: hidden;
  height: 400px;
  /* min-height: 300px; */
  width: 100%;
  /* margin: 20px; */
  /* border-radius: 5px; */
  /* border: solid 0px #fff; */
  position: relative;
  background-color: white;

  padding: 10px;
}

.block2 {
  height: 350px;
  width: 90%;
  background-color: white;
  align-items: center;
  margin: auto;
}
.about_heading {
  color: rgb(5, 5, 5);
  font-family: "Exo 2", sans-serif;
  font-size: 46px;
  font-weight: 900;
  text-transform: uppercase;
}

.square {
  height: 60px;
  width: 60px;
  border: 1px dashed #229fbf;
  margin: 0 0 0 55px;
  /*   padding: 1px; resize squares */
  background-color: rgba(255, 255, 255, 0.2);
  display: inline-block;
  transform: rotateZ(44deg);
}

.square:hover {
  background-color: #798184;
  transition: ease 0.2s;
  cursor: pointer;
}

.square .icons {
  position: absolute;
  transform: rotateZ(-44deg);
  margin: 20px 0 0px 21px;
}

.fa-facebook,
.fa-twitter,
.fa-dribbbler,
.fa-youtube,
.fa-dribbble {
  width: 11px;
  height: 22px;
  color: rgb(2, 2, 2);
  font-family: FontAwesome;
  font-size: 23px;
  font-weight: 400;
  text-transform: uppercase;
}

.square:hover .fa-facebook {
  color: rgba(59, 89, 152, 1);
}

.square:hover .fa-twitter {
  color: rgba(27, 182, 239, 1);
}

.square:hover .fa-dribbble {
  color: rgba(199, 59, 111, 1);
}

.square:hover .fa-youtube {
  color: rgba(229, 45, 39, 1);
}

.texting {
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  margin: 0px;
  color: #0b0b0b;
}

/* .site-main {
  background-color: white;
} */

.background-img {
  background-image: url(img.png);

  width: auto;
  background-repeat: no-repeat;
  background-size: 100% 100%;

  padding: 50px;
  /* position: absolute; */
  object-fit: cover;
}

/* Media queries 2nd sec*/
@media (min-width: 30em) {
  .col {
    float: left;
  }

  .fourth {
    width: 50%;
  }

  .small-box {
    width: 45%;
  }
}

@media (min-width: 57.5em) {
  .fourth {
    width: 25%;
  }

  .small-box {
    /* margin: 2.8rem 2.5%; */
    width: 20%;
  }
  .small-box:nth-child(n + 6) {
    margin-top: 0;
  }
}

/* about sec media queries */
/* Styles for screens smaller than 768px (Mobile) */
/* @media (max-width: 767px) {
  .about {
    height: auto;
    padding: 20px;
  }

  .block2 {
    height: auto;
    width: 90%;
  }

  .about_heading {
    font-size: 32px;
  }

  .square {
    margin: 0 0 0 30px;
  }

  .texting {
    font-size: 16px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .about {
    height: auto;
    padding: 20px;
  }

  .block2 {
    height: auto;
    width: 80%;
  }

  .about_heading {
    font-size: 36px;
  }

  .square {
    margin: 0 0 0 40px;
  }

  .texting {
    font-size: 16px;
  }
} */

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 7));
  }
}

.slider {
  background: #ffffff;
  box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125);
  height: 200px;
  margin: 10px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.slider::before,
.slider::after {
  background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%);
  content: "";
  height: 100%;
  position: absolute;
  width: 250px;
  z-index: 2;
}

.slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}

.slider::before {
  left: 0;
  top: 0;
}

.slider .slide-track {
  animation: scroll 40s linear infinite;
  display: flex;
  width: calc(250px * 19);
}

.slide-track {
  height: 90%;
  margin: 20px;
}

.slide {
  height: 90%;
  width: 250px;
  margin-right: 30px;
  margin-bottom: 30px;
}

.type {
  flex: 0 0 250px;
  margin-right: 20px;
  background-image: linear-gradient(to right, #28a0f0, #b100ec);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  height: 100%;
  width: 100%;
  border-radius: 5px;
  box-sizing: border-box;
  font-weight: bolder;
  font-size: 20px;
  font-style: oblique;

  text-shadow: 0 0 1px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

/* .icon {
  font-size: 36px;
  margin-bottom: 10px;
} */

.label {
  font-size: 18px;
  text-align: center;
}
