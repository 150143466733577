.chats{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
}
.applicantInfo{
    height: 3.5rem;
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 1rem;
    gap: 1rem;
    font-size: 17px;
    font-weight: 500;
}
.applicantInfo img{
    width: 45px;
    border-radius: 50%;
    object-fit: cover;
}

.applicantInfo:hover{
    background-color: #e1e1e1;
    cursor: pointer;
}

.applicantInfo:active{
    transform: scale(0.99);
}