.apply-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 7rem;
}

.apply-success-msg {
    width: 50%;
    min-width: 460px;
    border-left: 5px solid green;
    padding: 20px;
    background-color: #f9f9f9;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-bottom-left-radius: 0.3rem;
    border-top-left-radius: 0.3rem;
}

.apply-success-content {
    margin-left: 10px;
}

.apply-success-content h2 {
    margin-top: 0;
    color: green;
    text-align: center;
}

.job-details {
    margin: 10px 0;
}

.job-details h3{
    font-size: 25px;
}

.job-details p {
    margin: 5px 0;
    font-size: 18px;
}

.show-applicant-details{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.more-opportunities-content h2 {
    width:  100%;
    text-align: center;
    border-bottom: 1px solid #000;
    line-height: 0.1em;
    margin: 10px 0 20px;
}

h2 span {
    background:#fff;
    padding:0 10px;
}
.go-back-content{
    margin:2rem auto;

}
#go-back{
    border: none;
    color: #0f69f1;
    font-weight: 500;
}
.applicant-details h3{
    font-size: 26px;
}
.applicant-details p{
    font-size: 18px;
}


@media (max-width: 480px){
    .apply-success-msg{
        min-width: 100%;

    }

    .applicant-details{
        width: 90%;
    }
}