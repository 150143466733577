ul {
  margin: 0px;
  padding: 0px;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  /* overflow-x:hidden; */
}
.footer-section {
  background: rgb(3, 3, 61);
  position: relative;

  /* overflow-x: hidden; */
}

.cta-text {
  padding-left: 15px;
  display: inline-block;
  padding-bottom: 35px;
}
.cta-text h4 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2px;
}
.cta-text span {
  color: #757575;
  font-size: 15px;
}
.footer-content {
  position: relative;
  z-index: 2;
}
.footer-pattern img {
  position: absolute;
  top: 0;
  left: 0;
  height: 330px;
  background-size: cover;
  background-position: 100% 100%;
}
.footer-logo {
  margin-bottom: 30px;
}
.footer-logo img {
  max-width: 200px;
}
.footer-text p {
  margin-bottom: 14px;
  font-size: 14px;
  color: #7e7e7e;
  line-height: 28px;
}

.footer-social-icon i {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 38px;
  border-radius: 50%;
}

.footer-widget-heading h3 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 40px;
  position: relative;
}
/* .footer-widget-heading h3::before {
  content: "";
  position: relative;
  left: 35%;
  bottom: -15px;
  height: 2px;
  width: 100px;
  background: #1cd8f9;
} */
.footer-widget ul li {
  display: inline-block;
  /* float: left; */
  width: 50%;
  margin-bottom: 12px;
}
.footer-widget ul li a:hover, .footer-widget ul li .a:hover {
  color: #1cd8f9;
}
.a{
  font-weight: 600;
}
.footer-widget ul li a, .footer-widget ul li .a {
  color: #878787;
  text-transform: capitalize;
}
.subscribe-form {
  position: relative;
  overflow: hidden;
}
.subscribe-form input {
  width: 100%;
  padding: 14px 28px;
  background: #2e2e2e;
  border: 1px solid #2e2e2e;
  color: #fff;
  margin-left: 20px;
}
.subscribe-form button {
  position: absolute;
  right: 0;
  background: #1cd8f9;
  padding: 14px 20px;
  border: 1px solid #1cd8f9;
  top: 0;
  margin: 0;
}
.subscribe-form button {
  color: #fff;
  font-size: 15px;
  font-weight: 700;
}
.copyright-area .row {
  background: rgb(3, 3, 61);
  width: 80%;
  padding: 0px;
  height: 50px;
}
.copyright-text p {
  margin: 0;
  font-size: 14px;
  color: #878787;
}

.copyright-text p a {
  color: #1cd8f9;
}
.footer-menu li {
  display: inline-block;
  margin-left: 20px;
}
.footer-menu li:hover a {
  color: #1cd8f9;
}
.footer-menu li a{
  font-size: 14px;
  color: #878787;
}

.block {
  background-color: rgb(3, 3, 61);
  height: 380px;

  margin: auto;
  width: 90%;
  padding: 5px;
}

/* Styles for screens smaller than 768px (Mobile) */
@media (max-width: 767px) {
  .footer-section {
    padding: 40px 0;
  }

  .block {
    height: auto;
    padding: 20px;
  }

  .footer-widget-heading {
    margin-bottom: 20px;
  }

  .footer-widget ul li {
    width: 100%;
  }

  .subscribe-form input {
    margin-left: 0;
    margin-bottom: 10px;
  }

  .footer-menu li {
    margin-left: 0;
    margin-bottom: 10px;
  }
  .copyright-text p {
    padding: 0px;
  }
}

/* Styles for screens between 768px and 1023px (Tablet) */
@media (min-width: 768px) and (max-width: 1023px) {
  .block {
    height: auto;
    padding: 20px;
  }

  .footer-widget-heading {
    margin-bottom: 20px;
  }

  .footer-widget ul li {
    width: 50%;
  }

  .subscribe-form input {
    margin-left: 0;
    margin-bottom: 10px;
  }

  .footer-menu li {
    margin-left: 0;
    margin-bottom: 10px;
  }
}

/* Styles for screens larger than 1023px (Desktop) */
@media (min-width: 1024px) {
  .block {
    height: 380px;
  }
}
