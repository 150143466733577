:root {
  --bluecol: #82c7f3;
  --darkblue: rgb(3, 3, 61);
}

.box-container {
  background-color: #f9f9ff;
  /* background-color: skyblue; */
  min-height: 100vh;
  /* margin-top: 50px; */
}

.stack-container {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  width: 100%;
  /* background-color: skyblue; */
  gap: 1rem;
}

.left-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: 100%;
}
.right-section {
  /* background-color: skyblue; */
  width: 100%;
}

@media (min-width: 600px) {
  .stack-container {
    flex-direction: row;
    width: auto;
  }

  .left-section {
    flex: 2;
    flex-direction: column;
  }

  .right-section {
    flex: 5;
    height: auto;
    margin-top: 25px;
  }
}

.filter-card {
  /* min-width: 150px; */
  width: fit-content;
  margin-bottom: 3px;
  margin-top: 50px;
  padding: 20px;
  background-color: #ffffff;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  overflow: hidden;
}
.filter-heading {
  padding-bottom: 15px;
}

.filter-heading-text {
  color: var(--bluecol);
  font-weight: 600;
}
.location-card {
  min-width: 150px;
  margin-bottom: 3px;
  margin-top: 3px;
  padding: 20px;
  background-color: #f9f9ff;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  overflow: hidden;
}

.job-listings {
  flex: 5;
  padding: 2px;
  height: 100%;
}
.no-result {
  min-height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .load-box {
  margin-top: 20%;
} */
.pagination-container {
  /* margin: 25px 0px 30px 30%; */
}
.pagination-section{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}
.cardHeading{
  display: flex;
  gap: 1rem;
  /* background-color: green; */
  justify-content: space-between;
}
@media (max-width:600px) {
  .cardHeading{
    flex-direction: column;
    padding: 4px;
  }
  .cardHeading > .heading{
    font-size: 1.3rem;
  }
  .cardDescription{
    margin: 0px;
  }
}
