.application-container{

    margin-top: 6rem;

}

.application-content{
    display: flex;
    justify-content: center;
    align-items: center;
}

.applications-list{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    width: 100vw;
}

.applications-content h2{
    text-align: center;
    margin-bottom: 3rem;
    font-size: 50px;
}

.application-card{
    background-color: #fafafa;
    width: 40%;
    padding-left: 1rem;
    padding-bottom: 1rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

@media (max-width: 520px){
    .application-card{
        width: 90%;
    }
    .job-details h3{
        font-size: 20px;
        /*padding-right: 1rem;*/
    }
    .applications-content h2{
        font-size: 35px;

    }
}

@media (min-width: 521px) and (max-width:768px ) {
    .application-card{
        width: 85%;
    }
    .job-details h3{
        font-size: 23px;
        padding-right: 1rem;
    }
    .applications-content h2{
        font-size: 40px;
    }
}

@media (min-width: 769px) and (max-width:1080px ) {
    .application-card{
        width: 85%;
    }
    .job-details h3{
        font-size: 23px;
        padding-right: 1rem;
    }
    .applications-content h2{
        font-size: 40px;
    }
}

@media (min-width: 1081px) and (max-width:1240px ) {
    .application-card{
        width: 65%;
    }
    .job-details h3{
        font-size: 23px;
        padding-right: 1rem;
    }
    .applications-content h2{
        font-size: 40px;
    }
}

@media (min-width: 1241px) and (max-width:1320px ) {
    .application-card{
        width: 55%;
    }
    .job-details h3{
        font-size: 23px;
        padding-right: 1rem;
    }
    .applications-content h2{
        font-size: 40px;
    }
}

.view-applicant-details button{
    text-transform: none;
    font-size: 14px;
}

.job-details h3 strong{
    color: black;
    font-weight: 600;
}

.job-details h3{
    color: #2f2f33;
}

.applicant-details p strong{
    color: black;
}

.applicant-details h3{
    color: #000000;
}
.job-details p strong{
    color: black;
}