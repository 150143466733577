.saved-jobs-wrapper{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 8rem;
}

.saved-jobs-container h1{
    margin-top: 2rem;
}
.saved-jobs-container{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 60%;
    height: auto;
    background-color: #fafafa;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 10px;
}

.saved-jobs{
    width: 90%;
}

.saved-jobs p{
    text-align: center;
    font-size: 18px;
}

#go-back{
    border: none;
    background-color: white;
    text-decoration: underline;
    color: #0f69f1;
    font-weight: 500;
}

@media (min-width: 480px) and (max-width: 1080px){
    .saved-jobs-container{
        width: 90%;
    }
}

@media (max-width: 480px){
    .saved-jobs-container h1{
        font-size: 45px;
    }
    .saved-jobs-container{
        width: 100%;
    }
}