#allinternships {
    /* border: 3px solid black; */
    width: 100%;
    min-height: 56rem;
    /* background-color: skyblue; */
    /* background-color: rgb(240, 242, 244); */
    background-color: white;
    position: relative;
    height: 100%;
}

.tablesection {
    width: 100%;
}

#updatesection {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    min-height: 40rem;
    background-color: white;
    display: none;
}

.alljobsbtns {
    text-wrap: wrap;
    width: fit-content;
    padding: 4px;
    background-color: skyblue;
}

.alljobsbtns:hover {
    background-color: black;
    color: white;
}

table {
    /* border: 2px solid black; */
    width: 100%;
}

th,
td {
    border: 2px solid black;
    border-collapse: collapse;
    padding: 6px;
    text-wrap: wrap;
    font-size: 1.2rem;
    text-align: center;
}

#hire {
    width: 100%;
    min-height: 37rem;
    /* background-color: tomato; */
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    padding: 2rem;
}

.hiresection1 {
    padding: 1rem;
    /* background-color: white; */
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 3rem;
}
.hiresubsection1{
    font-size: 2.5rem;
    font-weight: 700;
    text-wrap: wrap;
    /* background-color: skyblue; */
    padding: 5px;
}
.hiresubsection2{
    width: 100%;
    padding: 5px;
    /* background-color: tomato; */
}
.hiresection2{
    padding: auto;
    /* background-color: skyblue; */
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.hiresection2 > img{
    width: auto;
    height: 25rem;
}
.postbtn{
    padding: 10px 20px;
    margin-top: 2rem;
    width: 12rem;
    background-color: skyblue;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1.7rem;
}
#internpostbtn{
    width: 15rem;
}
.postbtn:hover {
    background-color: black;
    color: white;
}
#spantext{
    display:inline;
    color: gold;
}
@media (max-width:1220px) {
    #allinternships {
        min-height: 55rem;
    }
    .hiresection1{
        gap: 2rem;
    }
    .hiresubsection1{
        font-size: 2rem;
    }
}

@media (max-width:1024px) {

    th,
    td {
        font-size: 1rem;
    }
    #hire {
        padding: 1rem;
    }
    .hiresection2 > img{
        height: 20rem;
    }
    .hiresubsection1{
        font-size: 1.7rem;
    }
    .postbtn{
        font-size: 1.3rem;
        width: 10rem;
    }
}

@media (max-width:768px) {

    th,
    td {
        padding: 4px;
    }

    .alljobsbtns {
        padding: 3px;
    }
    #hire {
        flex-direction: column-reverse;
    }
    .hiresection1 {
        width: 100%;
        height: 50%;
        align-items: center;
        text-align: center;
    }
    .hiresection2{
        width: 100%;
        height: 50%;
    }
    .hiresubsection1{
        font-size: 2rem;
    }
    .hiresubsection2{
        display: flex;
        justify-content: center;
    }
}

@media (max-width:665px) {
    #allinternships {
        min-height: 56rem;
    }
    #hire {
        padding: 1rem;
    }
}

@media (max-width:600px) {
    #allinternships {
        min-height: 92rem;
    }
    #hire{
        padding: .5rem;
    }
    .hiresection2 > img{
        height: 100%;
    }
    .hiresubsection1{
        font-size: 1.5rem;
    }
    .hiresection1{
        gap: 2rem;
    }
    th,
    td {
        font-size: .8rem;
        padding: 3px;
    }
    .alljobsbtns {
        padding: 2px;
    }
}

@media (max-width:500px) {
    .postbtn {
        margin-top: 0rem;
        font-size: 1.4rem;
    }
    th,
    td {
        font-size: .6rem;
    }

    /* .alljobsbtns {} */
}