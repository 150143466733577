.apply-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 8rem auto;
    padding: 1rem;
    /*border: 1px solid #ccc;*/
    border: none;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    /*box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;*/
    max-width: 700px;
    background-color: #fdfdfd;
}

.form {
    margin-top: 2rem;
}

.apply-form-content {
    width: 100%;
}
.apply-form-content h2{
    text-align: center;
}
.apply-form-content form {
    display: flex;
    flex-direction: column;
}

.apply-form-content form input,
.apply-form-content form textarea {
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    margin-bottom: 0.5rem;
}

.apply-form-content form input[type="checkbox"] {
    width: auto;
    margin-right: 0.5rem;
}

.apply-form-content form input[type="date"] {
    margin-top: 0.5rem;
}

.apply-form-content form label {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.apply-form-content form button {
    padding: 0.5rem;
    background-color: #180244;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 1rem;
}

.apply-form-content form button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.apply-form-content form button:hover:not(:disabled) {
    background-color: #000f77;
}

.apply-form-content form .error-message {
    color: red;
    font-size: 0.875rem;
}



/*old css*/
/*.apply-form-container {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    margin: 8rem auto;*/
/*    padding: 1rem;*/
/*    border: 1px solid #ccc;*/
/*    border-radius: 8px;*/
/*    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;*/
/*    max-width: 700px;*/
/*    background-color: #fff;*/
/*    height: auto;*/
/*}*/
/*form {*/
/*    margin-top: 2rem;*/
/*}*/
/*.apply-form-content {*/
/*    width: 100%;*/
/*    height: 100%;*/
/*}*/

/*.apply-form-content form {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*}*/

/*.apply-form-content form input,*/
/*.apply-form-content form textarea {*/
/*    padding: 0.5rem;*/
/*    border: 1px solid #ccc;*/
/*    border-radius: 4px;*/
/*    width: 100%;*/
/*}*/

/*.apply-form-content form button {*/
/*    padding: 0.5rem;*/
/*    background-color: #0277bd;*/
/*    color: #fff;*/
/*    border: none;*/
/*    border-radius: 4px;*/
/*    cursor: pointer;*/
/*}*/

/*.apply-form-content form button:disabled {*/
/*    background-color: #ccc;*/
/*    cursor: not-allowed;*/
/*}*/

/*.apply-form-content form button:hover:not(:disabled) {*/
/*    background-color: #01579b;*/
/*}*/

/*.apply-form-content form .error-message {*/
/*    color: red;*/
/*    font-size: 0.875rem;*/
/*}*/

/*label{*/
/*    margin-bottom: 1rem;*/
/*}*/