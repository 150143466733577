.loading{
    height: 100vh;
    width: 100%;
    background-color: white;
    display: grid;
    place-items: center;
}
.loading > div{
    width: 10vmax;
    height: 10vmax;
    border-bottom: 8px solid black;
    border-radius: 50%;
    animation: loadingRotate 800ms linear infinite;
}
@keyframes loadingRotate {
    to {
        transform: rotateZ(-360deg);
    }
}